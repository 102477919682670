

























































































































































































































































































import { computed, defineComponent, ref } from '@vue/composition-api';
import { useGift, expirationTypeItems, expirationPeriodIntervalItems, getExpirationTypeText, getExpirationPeriodIntervalText } from '@/admin/gift';
import { useNotification } from '@/composition/notification';
import { useConfirm } from '@/composition/confirm';
import FcDate from '@/components/FcDate.vue';
import FcImage from '@/components/FcImage.vue';
import FcStaticImage from '@/components/FcStaticImage.vue';
import FcAuthority from '@/components/FcAuthority.vue';
import FcNumberForm from '@/components/FcNumberForm.vue';
import myAttributes from '@/composition/myAttributes';
import FcRoleLoading from '@/components/FcRoleLoading.vue';
import FcRoleDeny from '@/components/FcRoleDeny.vue';

export default defineComponent({
  name: 'Gift',
  components: {
    FcDate,
    FcImage,
    FcStaticImage,
    FcAuthority,
    FcNumberForm,
    FcRoleLoading,
    FcRoleDeny,
  },
  props: {
    giftId: {
      type: String,
      require: false,
      default: '',
    },
  },
  setup({ giftId }, context) {
    const myRoleSettings = computed(() => myAttributes.getRoleSettings('gifts'));

    const { gift } = useGift(giftId);
    const canUpdateExpirationProperties = ref(!giftId);
    const isSaving = ref(false);
    const notification = useNotification();
    const { confirmDialog } = useConfirm();

    const save = async () => {
      if (!(await confirmDialog('本当に保存しますか？'))) return;
      gift.isSaving = true;
      try {
        if (giftId) {
          await gift.saveGift(giftId);
          notification.notify('変更しました。');
        } else {
          await gift.createGift();
          notification.notify('作成しました。');
          context.root.$router.push('/gifts');
        }
      } catch (error) {
        notification.error(error);
      }
      gift.isSaving = false;
    };

    return {
      pageTitle: '特典',
      canUpdateExpirationProperties,
      myRoleSettings,
      gift,
      isSaving,
      getExpirationTypeText,
      getExpirationPeriodIntervalText,
      save,
      expirationTypeItems,
      expirationPeriodIntervalItems,
    };
  },
});
